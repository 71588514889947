import type { AxiosInstance, AxiosPromise, AxiosRequestConfig } from 'axios';

export const createDedup = () => {
  let dedupCache: Record<string, AxiosPromise<unknown>> = {};

  const dedupGetRequest = <T = any>(
    path: string,
    client: AxiosInstance,
    config?: AxiosRequestConfig<unknown>,
  ): AxiosPromise<T> => {
    const key = `${path}-${JSON.stringify(config ?? {})}`;
    if (dedupCache[key]) {
      return dedupCache[key] as AxiosPromise<T>;
    }
    const promise = client.get<T>(path, config);
    dedupCache[key] = promise;

    promise
      .then(() => {
        delete dedupCache[key];
      })
      .catch(() => {
        delete dedupCache[key];
      });

    return promise as AxiosPromise<T>;
  };

  const resetDedupCache = () => {
    dedupCache = {};
  };

  return { dedupGetRequest, resetDedupCache };
};
