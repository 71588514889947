import React from 'react';

interface Props {
  domain: string | undefined;
  gtmId: string;
  gtmAuthString: string;
}

interface GTMInitProps {
  isOneTrustEnabled: boolean;
}

export const GTMNoScriptTag = ({
  domain = 'https://gtm.mentimeter.com',
  gtmId,
  gtmAuthString,
}: Props) => (
  <noscript>
    <iframe
      sandbox="allow-scripts allow-same-origin"
      src={`${domain}/ns.html?id=${gtmId}${gtmAuthString}`}
      height="0"
      width="0"
      style={{ display: 'none', visibility: 'hidden' }}
    />
  </noscript>
);

// Saves originalLocation to be able to persist campaign data throughout a session
export const GTMDataLayerInit = () => (
  <script
    id="gtm-init-script"
    type="text/javascript"
    dangerouslySetInnerHTML={{
      __html: `
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        originalLocation:
          document.location.protocol +
          '//' +
          document.location.hostname +
          document.location.pathname +
          document.location.search,
     });`,
    }}
  />
);

/**
 * Special Tag which adds default groups to GTM if OneTrust is disabled
 * We need it in order to make triggers work, otherwise whole GTM and GA4 will not just inject scripts
 * Has to be removed after OneTrust integration
 * @property boolean isOneTrustEnabled detects whenever OneTrust is enabled or not (we do want it to work when OneTrust is disabled)
 * @returns React.ReactNode
 */
export const GTMOneTrustTag = ({ isOneTrustEnabled }: GTMInitProps) => {
  if (isOneTrustEnabled) {
    return null;
  }

  return (
    <script
      id="gtm-onetrust-script"
      type="text/javascript"
      dangerouslySetInnerHTML={{
        __html: `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('consent', 'default', { 
        'ad_personalization': 'granted', 
        'ad_storage': 'granted', 
        'ad_user_data': 'granted', 
        'analytics_storage': 'granted',
        'functionality_storage': 'granted',
        'personalization_storage': 'granted',
        'security_storage': 'granted'
        }); 
        window.dataLayer.push({ event: "OneTrustGroupsUpdated", OnetrustActiveGroups: ",C0001,C0002,C0003,C0004," })
        `,
      }}
    />
  );
};

/**
 * GTM OneTrust Consent Mode V2 tag
 * Please make sure you implement it BEFORE initiating OneTrust or GTM, since it's default values which will then be overwritten bt OneTrust
 * More info here: https://my.onetrust.com/s/article/UUID-d81787f6-685c-2262-36c3-5f1f3369e2a7
 * @param boolean isOneTrustEnabled detects whenever OneTrust is enabled or not (we don't want it to work when OneTrust is disabled)
 * @returns React.ReactNode
 */
export const GTMConsentModeTag = ({ isOneTrustEnabled }: GTMInitProps) => {
  if (!isOneTrustEnabled) {
    return null;
  }

  return (
    <script
      id="gtm-consent-mode-script"
      type="text/javascript"
      dangerouslySetInnerHTML={{
        __html: `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('consent', 'default', { 
        'ad_personalization': 'denied', 
        'ad_storage': 'denied', 
        'ad_user_data': 'denied', 
        'analytics_storage': 'denied', 
        'functionality_storage': 'denied',
        'personalization_storage': 'denied',
        'security_storage': 'denied'
        }); 
        `,
      }}
    />
  );
};

export const GTMHeadTag = ({
  domain = 'https://gtm.mentimeter.com',
  gtmId,
  gtmAuthString,
}: Props) => (
  <script
    id="gtm-head-script"
    type="text/javascript"
    dangerouslySetInnerHTML={{
      __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          '${domain}/gtm.js?id='+i+dl+'${gtmAuthString}';f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer', '${gtmId}');`,
    }}
  />
);
