'use client';

import Script from 'next/script';
import React from 'react';
import { captureException, MentiError } from '@mentimeter/errors/sentry';

export function MazeScript() {
  return (
    <Script strategy="lazyOnload" onError={handleError}>
      {`
    (function (m, a, z, e) {
        var s, t;
        try {
          t = m.sessionStorage.getItem('maze-us');
        } catch (err) {}
    
        if (!t) {
          t = new Date().getTime();
          try {
            m.sessionStorage.setItem('maze-us', t);
          } catch (err) {}
        }

        s = a.createElement('script');
        s.src = z + '?apiKey=' + e;
        s.async = true;
        a.getElementsByTagName('head')[0].appendChild(s);
        m.mazeUniversalSnippetApiKey = e;
    }
  )(window, document, 'https://snippet.maze.co/maze-universal-loader.js', '6512f602-240e-42b4-81f8-5e7dc2be6c43');
      `}
    </Script>
  );

  function handleError(error: Error) {
    captureException(
      new MentiError('Loading Maze.co script', {
        feature: 'paid-user-growth',
        cause: error,
      }),
    );
  }
}
