import Cookies from '@mentimeter/cookies';
import { Categories } from './types';

const COOKIE_CONSENT_NAME = 'OptanonConsent';

/**
 * Fallback cookies in case OneTrust script is not enabled
 * Might be set to only ESSENTIAL after initial launch
 */
const FALLBACK_COOKIE_CATEGORIES = [
  Categories.ESSENTIAL,
  Categories.PERFORMANCE,
  Categories.FUNCTIONAL,
  Categories.TARGETING,
];

export const getCategories = (): Categories[] => {
  if (typeof window === 'undefined' || !window.OnetrustActiveGroups) {
    // backup method of reading consent from cookies
    // could trigger when, for example, we rendered this hook on server side or too early, so banner didn't come up yet
    const consent = Cookies.get(COOKIE_CONSENT_NAME);

    if (!consent) {
      // no consent. Probably, OneTrust is disabled, return fallback all groups
      return FALLBACK_COOKIE_CATEGORIES;
    }

    try {
      const consentParams = new URLSearchParams(consent);
      const consentGroups = consentParams.get('groups');

      if (!consentGroups) {
        // consent cookie valid but groups somehow missing, returning fallback groups
        return FALLBACK_COOKIE_CATEGORIES;
      }

      return consentGroups // "C0001:1,C0002:0,C0003:0,C0004:1"
        .split(',') // ["C0001:1", "C0002:0", "C0003:0", "C0004:1"]
        .filter((g) => g.endsWith(':1')) // ["C0001:1", "C0004:1"]
        .map((g) => g.slice(0, -2)) as Categories[]; // ["C0001", "C0004"]
    } catch (_) {
      // consent cookie invalid, returning fallback groups
      return FALLBACK_COOKIE_CATEGORIES;
    }
  }

  return window.OnetrustActiveGroups.split(',').filter(Boolean) as Categories[];
};
