import type { UserFlagsT } from '@mentimeter/http-clients';
import type { UtmParams } from './types';
import { LAST_TOUCH_KEY, LAST_TIMESTAMP_KEY, FIRST_TOUCH_KEY } from './utmKeys';
import { getUtmParamsFromCookies } from './getUtmParamsFromCookies';

export const getStoredUtmFlags = (
  userFlags: UserFlagsT | null,
): UtmParams | null => {
  const utmParams = getUtmParamsFromCookies();
  if (!utmParams || !userFlags) {
    return null;
  }

  // Set the last touch key if it's not already set, or if the timestamp on the Cookie key is later than the given one
  let result = null;
  if (
    !userFlags[LAST_TOUCH_KEY] ||
    (utmParams[LAST_TOUCH_KEY] &&
      // @ts-expect-error-auto TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      new Date(utmParams[LAST_TOUCH_KEY][LAST_TIMESTAMP_KEY]) >
        // @ts-expect-error-auto TS(2769) FIXME: No overload matches this call.
        new Date(userFlags[LAST_TOUCH_KEY][LAST_TIMESTAMP_KEY]))
  ) {
    result = {
      [LAST_TOUCH_KEY]: utmParams[LAST_TOUCH_KEY],
    };
  }

  if (!userFlags[FIRST_TOUCH_KEY] && utmParams[FIRST_TOUCH_KEY]) {
    result = {
      ...result,
      [FIRST_TOUCH_KEY]: utmParams[FIRST_TOUCH_KEY],
    };
  }
  return result;
};
