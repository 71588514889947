declare global {
  interface Window {
    OnetrustActiveGroups: string;
    OneTrust: {
      ToggleInfoDisplay: () => void;
      Close: () => void;
    };
  }
}

export enum Categories {
  /** Essential cookies */
  ESSENTIAL = 'C0001',
  /** Performance cookies */
  PERFORMANCE = 'C0002',
  /** Functional cookies */
  FUNCTIONAL = 'C0003',
  /** Targeting cookies */
  TARGETING = 'C0004',
}
