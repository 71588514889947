import type { AxiosInstance } from 'axios';
import type { EditorCoreClient } from './types';

export const getEditorHttpClient = (
  client: AxiosInstance,
): EditorCoreClient => {
  return {
    editor: {
      slideDeck: {
        get(slideDeckId, config) {
          return client.get(`/editor/series/${slideDeckId}`, config);
        },
        migrate(seriesId, config) {
          return client.put(`/editor/series/${seriesId}/migrate`, '', config);
        },
        rollbackMigration(slideDeckId, config) {
          return client.delete(`/editor/slide-deck/${slideDeckId}`, config);
        },
        addImage(slideDeckId, path, altText, crop, config) {
          return client.post(
            `/editor/slide-deck/${slideDeckId}/image`,
            {
              path,
              altText,
              crop,
            },
            config,
          );
        },
        updateImage(slideDeckId, imageId, altText, crop, config) {
          return client.put(
            `/editor/slide-deck/${slideDeckId}/image/${imageId}`,
            {
              altText,
              crop,
            },
            config,
          );
        },
      },

      slide: {
        updateStaticContent(slideId, content, config) {
          return client.put(
            `/editor/slide/${slideId}/static-content`,
            { staticContent: content },
            config,
          );
        },
      },
      interactiveContent: {
        updateInteractiveContent(interactiveContentId, content, config) {
          return client.put(
            `/editor/interactive-content/${interactiveContentId}`,
            content,
            config,
          );
        },
      },
    },
  };
};
