'use client';

import {
  GTMConsentModeTag,
  GTMDataLayerInit,
  GTMHeadTag,
  GTMNoScriptTag,
  GTMOneTrustTag,
} from '@mentimeter/google-tracking';

const GTM_DOMAIN = process.env.NEXT_PUBLIC_GTM_DOMAIN;
const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID as string;
const GTM_AUTH = process.env.NEXT_PUBLIC_GTM_AUTH;
const GTM_ENVIROMENT_PREVIEW_NAME =
  process.env.NEXT_PUBLIC_GTM_ENVIROMENT_PREVIEW_NAME;

const ONETRUST_DOMAIN_SCRIPT = process.env.NEXT_PUBLIC_ONETRUST_DOMAIN_SCRIPT;

const environmentParamStr =
  GTM_AUTH && GTM_ENVIROMENT_PREVIEW_NAME
    ? `&gtm_auth=${GTM_AUTH}&gtm_preview=${GTM_ENVIROMENT_PREVIEW_NAME}&gtm_cookies_win=x`
    : ``;

export const GtmHead = () => {
  return (
    <>
      <GTMConsentModeTag isOneTrustEnabled={!!ONETRUST_DOMAIN_SCRIPT} />
      <GTMDataLayerInit />
      <GTMOneTrustTag isOneTrustEnabled={!!ONETRUST_DOMAIN_SCRIPT} />
      <GTMHeadTag
        domain={GTM_DOMAIN}
        gtmId={GTM_ID}
        gtmAuthString={environmentParamStr}
      />
    </>
  );
};

export const GtmScript = () => {
  return (
    <GTMNoScriptTag
      domain={GTM_DOMAIN}
      gtmId={GTM_ID}
      gtmAuthString={environmentParamStr}
    />
  );
};
