'use client';

import { useState, useRef } from 'react';
import { useServerInsertedHTML } from '@mentimeter/next-navigation';
import {
  createRenderer as felaCreateRenderer,
  renderToNodeList,
  RendererProvider,
  prefixer,
  fallback,
} from '@mentimeter/ragnar-react';
import * as Fonts from '@mentimeter/ragnar-fonts';
import { reset, setup } from '@mentimeter/ragnar-reset';

export function FelaProvider({ children }: { children: React.ReactNode }) {
  const hasInsertedHTML = useRef(false);
  const [renderer] = useState(() => createRenderer());

  useServerInsertedHTML(() => {
    if (!hasInsertedHTML.current) {
      hasInsertedHTML.current = true;
      return renderToNodeList(renderer);
    }
    return null;
  });
  return <RendererProvider renderer={renderer}>{children}</RendererProvider>;
}

function createRenderer(): any {
  const renderer = felaCreateRenderer({
    plugins: [prefixer(), fallback()],
    selectorPrefix: 'f-',
    mediaQueryOrder: [
      'screen and (min-width: 600px)',
      'screen and (min-width: 960px)',
      'screen and (min-width: 1200px)',
    ],
  });

  Object.keys(Fonts.MentiText).forEach((key) => {
    renderer.renderFont(
      'MentiText',
      Fonts.MentiText[key as keyof typeof Fonts.MentiText].files,
      Fonts.MentiText[key as keyof typeof Fonts.MentiText].style,
    );
  });

  Object.keys(Fonts.MentiDisplay).forEach((key) => {
    renderer.renderFont(
      'MentiDisplay',
      Fonts.MentiDisplay[key as keyof typeof Fonts.MentiDisplay].files,
      Fonts.MentiDisplay[key as keyof typeof Fonts.MentiDisplay].style,
    );
  });

  const selector = `body`;
  const extend = setup(selector);
  renderer.renderStatic(reset(extend));

  return renderer;
}
