import React from 'react';
import type { Categories } from './types';
import { getCategories } from './getCategories';

type CallbackFn = (categories: Categories[]) => void;
interface Options {
  once?: boolean;
}

/**
 * A hook for executing some code after OneTrust is initiated or updated
 * Basically, it executes callback provided only after OneTrust cookie banner is set and when categories have changed
 * @param {CallbackFn} cb Callback triggered when OneTrust finishes set up
 * @param {Options} options extra options for OneTrust event listener
 */
export default function useOneTrustReady(
  cb: CallbackFn,
  { once = true }: Options | void = { once: true },
) {
  React.useEffect(() => {
    const handler = () => cb(getCategories());

    if (window.OneTrust) {
      handler();
    }

    window.addEventListener('OneTrustGroupsUpdated', handler, { once });

    return () => {
      window.removeEventListener('OneTrustGroupsUpdated', handler);
    };
  }, [cb, once]);
}
