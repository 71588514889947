import React from 'react';
import { setUser as setSentryUser } from '@sentry/nextjs';
import {
  getLocalUser,
  getSessionToken,
  removeLocalUserAndSessionToken,
  setUserLocalStorageAndCookies,
} from '@mentimeter/user';
import {
  core,
  type UserFlagsT,
  type UserResponseT,
} from '@mentimeter/http-clients';
import {
  getSplitDataSync,
  getVisitorToken,
  VISITOR_LOCAL_STORAGE_KEY,
} from '@mentimeter/splitio';
import { useOneTrustReady } from '@mentimeter/onetrust';
import { trackUser } from '@api/tracking/client';
import { TrackingMethods } from '@mentimeter/google-tracking';
import { billingRules, useSubscriptions } from '@mentimeter/billing';
import { trackVisitor } from 'src/trackVisitor';
import { AppStateContext } from 'src/appState';
import { getStoredUtmFlags } from '@mentimeter/utm';
import { LocalStorage } from '@mentimeter/storage';

const ONETRUST_DOMAIN_SCRIPT = process.env.NEXT_PUBLIC_ONETRUST_DOMAIN_SCRIPT;

const _user = getLocalUser();
if (_user) setSentryUser({ id: String(_user.id) });

export function AppStateProvider({
  children,
  newlyGeneratedServerToken,
}: {
  children: React.ReactNode;
  newlyGeneratedServerToken: string;
}) {
  const [user, setUser] = React.useState<UserResponseT | null>(null);
  const [userFinishedLoading, setUserFinishedLoading] = React.useState(false);
  const { subscriptions } = useSubscriptions(!user);
  const { planCategory } = billingRules(subscriptions);

  const localStorageVisitorToken = getVisitorToken();
  // we want to ensure that we don't re-render the page if we don't have a token in localStorage
  // so let's set it and use the one coming from the server as a fallback
  if (!localStorageVisitorToken) {
    LocalStorage.setItem({
      key: VISITOR_LOCAL_STORAGE_KEY,
      value: newlyGeneratedServerToken,
      type: 'functional',
    });
  }

  const visitorToken = localStorageVisitorToken || newlyGeneratedServerToken;

  const trackPageView = React.useCallback(() => {
    const localUser = getLocalUser();
    const { location } = window;
    const { preferredLanguage, country } = getSplitDataSync();

    const payload = {
      event: 'Viewed page',
      properties: { Page: location.pathname },
    };

    trackVisitor(payload);

    if (localUser) {
      trackUser(payload);
    }

    TrackingMethods.trackPageView({
      page_location: location.pathname,
      page_title: document.title,
      country,
      language: preferredLanguage,
      logged_in: !!localUser,
      page_type: 'public',
      plan_type: planCategory,
      user_purpose: localUser?.flags.onboarding,
    });
  }, [planCategory]);

  const logout = async () => {
    await removeLocalUserAndSessionToken();
    setUser(null);
    setSentryUser(null);
  };

  // triggers first, get user and set loading state
  React.useEffect(() => {
    const localUser = getLocalUser();
    const sessionToken = getSessionToken(localUser);

    if (sessionToken || localUser) {
      core()
        .users.fetchUser()
        .then(async ({ data }) => {
          await setUserLocalStorageAndCookies(data);
          setUser((state) => ({ ...state, ...data }));
        })
        .catch(async (e) => {
          if (e.response?.data?.status === 401) {
            // intercomLogout(); does not work - needs timeout (?).
            // user session has been revoked
            await logout();
          }
        });
    }

    setUser(localUser);
    setUserFinishedLoading(true);
  }, []);

  // triggers second
  React.useEffect(() => {
    if (!userFinishedLoading) return;

    // TODO: we need to move it to layout (why?)
    if (!ONETRUST_DOMAIN_SCRIPT) {
      // backup sending trackPageView in case OneTrust is disabled
      trackPageView();
    }

    // triggers third, save utm flags
    // this could be another effect, but we lack proper effect trigger since we get user from local storage and set it synchronously
    const localUser = getLocalUser();

    if (!localUser) return;

    const flags = getStoredUtmFlags(localUser.flags);

    if (!flags) return;

    core()
      .users.saveUtmFlags({
        flags: {
          utm_last:
            (flags.utm_last as UserFlagsT['utm_last']) ||
            localUser.flags?.utm_last,
          utm_first:
            (flags.utm_first as UserFlagsT['utm_first']) ||
            localUser.flags?.utm_first,
        },
      })
      .then(async ({ data }) => setUserLocalStorageAndCookies(data))
      .catch(async (error) => {
        if (error.response?.status === 401) {
          await logout();
        }
      });
  }, [trackPageView, userFinishedLoading]);

  // this will trigger when OneTrust is ready and cookie banner is loaded or when cookie categories are changed
  // the reason for that is GTM (and GA) wants us to trigger page view only after we set categories in OneTrust
  // won't trigger if OneTrust is disabled
  useOneTrustReady(trackPageView, { once: false });

  return (
    <AppStateContext.Provider
      value={{ user, visitorToken, userFinishedLoading }}
    >
      {children}
    </AppStateContext.Provider>
  );
}
