'use client';

import React, { useState } from 'react';
import { LocalStorage } from '@mentimeter/storage';
import Cookies from '@mentimeter/cookies';
import { SPLIT_OVERRIDES_KEY } from '../constants';
import type { SplitFeatureOverrides } from './SplitOverridesContext';
import { SplitOverridesContext } from './SplitOverridesContext';

declare global {
  // eslint-disable-next-line no-var
  var __mentimeterRuntime: string;
}

function migrateLocalStorageToCookie() {
  const localStorageOverrides =
    LocalStorage.getJSONItem(SPLIT_OVERRIDES_KEY) ?? {};

  Cookies.setJSON({
    type: 'functional',
    name: SPLIT_OVERRIDES_KEY,
    value: localStorageOverrides,
    options: {
      expires: 365,
      sameSite: 'lax',
    },
  });
}

function getSplitOverrides(): SplitFeatureOverrides {
  if (Cookies.getJSON(SPLIT_OVERRIDES_KEY) === undefined) {
    migrateLocalStorageToCookie();
  }

  // If we decide to keep the overrides in cookie
  // return the value from cookie instead.
  return LocalStorage.getJSONItem(SPLIT_OVERRIDES_KEY) ?? {};
}

function setSplitOverrides(overrides: SplitFeatureOverrides) {
  // If we decide to keep the overrides in cookie
  // we can remove the LocalStorage setter.
  LocalStorage.setJSONItem({
    type: 'functional',
    key: SPLIT_OVERRIDES_KEY,
    value: overrides,
  });
  Cookies.setJSON({
    type: 'functional',
    name: SPLIT_OVERRIDES_KEY,
    value: overrides,
    options: {
      expires: 365,
      sameSite: 'lax',
    },
  });
}

export const SplitOverridesProvider = ({
  children,
  isAuthorizedToInteractWithDevelopmentTools,
  experimentOverrides,
}: {
  children: React.ReactNode;
  isAuthorizedToInteractWithDevelopmentTools: boolean;
  experimentOverrides?:
    | {
        manualOverrides: SplitFeatureOverrides;
        experiments: Record<string, readonly string[]>;
      }
    | undefined;
}) => {
  const [localStorageOverrides, setChangedSplits] =
    React.useState<SplitFeatureOverrides>({});
  const [hasLoadedFromStorage, setHasLoadedFromStorage] = useState(false);

  const { manualOverrides, experiments } = experimentOverrides ?? {};

  // Initiate from localstorage
  React.useEffect(() => {
    const storedOverrides: SplitFeatureOverrides = getSplitOverrides();
    setChangedSplits(storedOverrides);
    setHasLoadedFromStorage(true);
  }, []);

  const overrides = React.useMemo(() => {
    if (
      !isAuthorizedToInteractWithDevelopmentTools ||
      !manualOverrides ||
      !experiments ||
      Object.keys(experiments).length === 0
    ) {
      return localStorageOverrides;
    }

    const filteredManualOverrides = Object.fromEntries(
      Object.entries(manualOverrides).filter(([key, value]) =>
        experiments[key]?.includes(value),
      ),
    );

    return { ...localStorageOverrides, ...filteredManualOverrides };
  }, [
    isAuthorizedToInteractWithDevelopmentTools,
    manualOverrides,
    experiments,
    localStorageOverrides,
  ]);

  // Sync it up
  React.useEffect(() => {
    if (!hasLoadedFromStorage) return;

    setSplitOverrides(overrides);
  }, [hasLoadedFromStorage, overrides]);

  const value = React.useMemo(
    () => ({
      values: overrides,
      set: (splitName: string, nextValue: string) => {
        setChangedSplits((current) => ({ ...current, [splitName]: nextValue }));
      },
      reset: (splitName: string) => {
        setChangedSplits(({ ...copy }) => {
          delete copy[splitName];
          return copy;
        });
      },
      clearAll: () => {
        setChangedSplits({});
      },
      fromTreatments: (treatments: Record<string, string>) => {
        return Object.keys(treatments).reduce((prev, curr) => {
          return {
            ...prev,
            [curr]: overrides[curr] ?? treatments[curr],
          };
        }, {}) as any;
      },
    }),
    [overrides],
  );

  return (
    <SplitOverridesContext.Provider value={value}>
      {children}
    </SplitOverridesContext.Provider>
  );
};
