/* eslint-disable menti-react/filename-convention--jsx */
import React from 'react';
import { SplitClient, SplitFactory } from '@splitsoftware/splitio-react';
import { SplitOverridesProvider } from '@mentimeter/splitio';
import { useSearchParams } from '@mentimeter/next-navigation';
import { prefixUserIdWithUS } from '@mentimeter/region';
import { isAuthorizedToInteractWithDevelopmentTools } from '@mentimeter/user';
import { visitorExperiments, userExperiments } from './split-experiments';
import { useAppState } from './appState';
import { SplitIOUserProvider, SplitIOVisitorProvider } from './split-hooks';

const SPLIT_BROWSER_API_KEY = process.env
  .NEXT_PUBLIC_SPLIT_BROWSER_API_KEY as string;

const experiments = {
  ...visitorExperiments,
  ...userExperiments,
};

/**
 * Component that will create a new SplitIO client instance that will target users.
 * It will exposed on a React context and retrieved by SplitIOUserProviderInner
 */
const SplitIOUserProviderOuter = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { user, userFinishedLoading } = useAppState();
  if (userFinishedLoading && user !== null) {
    return (
      <SplitClient
        splitKey={prefixUserIdWithUS({
          userId: user.user_id,
          region: user.region,
        })}
        trafficType="user"
      >
        <SplitIOUserProvider>{children}</SplitIOUserProvider>
      </SplitClient>
    );
  }
  return (
    <SplitIOUserProvider forceControlTreatment>{children}</SplitIOUserProvider>
  );
};

/**
 * This is not a fool proof JWT decoding fn, but it is good enough for the simple UUIDs we use.
 *
 * https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript-without-using-a-library
 * @param token A JWT string
 * @returns The decoded JWT object
 */
function parseJwtWellEnough(token: string) {
  try {
    return JSON.parse(Buffer.from(token.split('.')[1]!, 'base64').toString());
  } catch (e) {
    return null;
  }
}

/**
 * Provider component that will create two SplitIO clients instances .
 * One to target visitors and one that target users. These two SplitIO clients will also be
 * exposed on React context.
 */
export const SplitIOSetup = ({ children }: { children: React.ReactNode }) => {
  const { visitorToken, userFinishedLoading } = useAppState();
  const searchParams = Object.fromEntries(useSearchParams() || []);

  const { visitorKey } = parseJwtWellEnough(visitorToken || '') ?? {};
  const splitIOVisitorConfig: SplitIO.IBrowserSettings = React.useMemo(
    () => ({
      core: {
        authorizationKey: SPLIT_BROWSER_API_KEY,
        key: visitorKey,
        trafficType: 'visitor',
      },
      startup: {
        readyTimeout: 5, // 5 seconds
      },
      storage: {
        type: 'LOCALSTORAGE',
      },
    }),
    [visitorKey],
  );
  // visitorToken will always be null server side meaning that we will render loading state server side
  const readyToStartTesting =
    visitorToken !== null && visitorKey !== undefined && userFinishedLoading;

  const experimentOverrides = {
    manualOverrides: searchParams,
    experiments,
  };

  if (readyToStartTesting) {
    return (
      <SplitOverridesProvider
        isAuthorizedToInteractWithDevelopmentTools={isAuthorizedToInteractWithDevelopmentTools()}
        experimentOverrides={experimentOverrides}
      >
        <SplitFactory config={splitIOVisitorConfig}>
          <SplitIOVisitorProvider>
            <SplitIOUserProviderOuter>{children}</SplitIOUserProviderOuter>
          </SplitIOVisitorProvider>
        </SplitFactory>
      </SplitOverridesProvider>
    );
  } else {
    return (
      <SplitOverridesProvider
        isAuthorizedToInteractWithDevelopmentTools={isAuthorizedToInteractWithDevelopmentTools()}
        experimentOverrides={experimentOverrides}
      >
        <SplitIOVisitorProvider fallbackToControlAfterTimeout>
          <SplitIOUserProvider fallbackToControlAfterTimeout>
            {children}
          </SplitIOUserProvider>
        </SplitIOVisitorProvider>
      </SplitOverridesProvider>
    );
  }
};
